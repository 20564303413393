<template>
  <b-row class="mb-32">
    <b-col cols="12">
      <b-row>
        <b-col cols="12" class="flex-grow-1 overflow-hidden">
          <div>
            <h1>Hello {{ username }},</h1>
          </div>
          <br />
          <div>
            <p>Be Respectful, Stay Positive and Have Fun...</p>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <b-card class="overflow-hidden border-1">
            <p>
              <b>A message from Steve</b><br />Founder & CEO of Undisputed
              Noobs.
            </p>

            <b-embed type="video" aspect="16by9" controls>
              <source
                src="https://sacul.cloud/img/unp/welcome_message.mp4"
                type="video/mp4"
              />
            </b-embed>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BCardImg,
  BEmbed,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BModal,
    BCard,
    BCardBody,
    BCardHeader,
    BCardImg,
    BEmbed,
  },

  data() {
    return {
      // return full vuex state
      data: this.$store.state,
      username: this.$store.state.auth.user.username,
    };
  },
  methods: {},
};
</script>
